import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/layouts/mdlayout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEOPage = makeShortcode("SEOPage");
const BenefitsHeadingPara = makeShortcode("BenefitsHeadingPara");
const InformationCard = makeShortcode("InformationCard");
const BenefitsHeadingButton = makeShortcode("BenefitsHeadingButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOPage title="Benefits for Schools" description="Explore how Dynos is beneficial for schools" image="standards" mdxType="SEOPage" />
    <BenefitsHeadingPara heading="Empower your school to learn and create with its own interactive digital content" subHeading="With Dynos, school leaders can manage all the digital content created by teachers without investing in expensive content from big publishers. You’ll be surprised at just how much your students and teachers can do with our powerful built-in features." button="Request a Demo" image="/school.png" className="school" mdxType="BenefitsHeadingPara" />
    <InformationCard name="expectancy" question="How likely does the user think they will be successful using the product? What resources do you have?" answer1="Since Dynos provides a comprehensive ready-made interactive digital content for their school along with training, admins feel comfortable signing up for Dynos." mdxType="InformationCard" />
    <InformationCard name="value" question="How will this product add value or address needs? Which needs is the product addressing?" answer1="With a single log in, admins can easily monitor reading engagement, assignment results of any student from any class." mdxType="InformationCard" />
    <InformationCard name="cost" question="What do users have to give up to use this product? How can we minimize this cost?" answer1="To best utilize Dynos digital content, schools need to be equipped with projectors or LCD monitors in some classes." answer2="To minimize the cost, Dynos can work with third-party vendors to supply the necessary equipment." mdxType="InformationCard" />
    <BenefitsHeadingButton heading="Get in touch with us to know more how Dynos can help your school." button="Request a Demo" mdxType="BenefitsHeadingButton" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      